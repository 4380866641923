import { Cart, ProfitabilitiesPayload } from '../store/data/types'
import { apiPost, loadBody } from './api'

import { dispatch } from '../store'
import { profitabilitiesActions } from '../store/slices/profitabilities'
import { Session } from '../store/slices/session'

export const getProfitabilities = async (cart: Cart, session: Session) => {
  const data = {
    cart: cart,
    session: {
      merchantCode: session.merchantCode,
      geoRegionId: session.geoRegionId,
      pricingGroupId: session.websiteRegionId,
      clubberEmail: session.clubberEmail,
    },
  }
  const response = await apiPost('/profitability-calculator', { data }, false)
  switch (response.status) {
    case 200: {
      const body = await loadBody(response)
      dispatch(profitabilitiesActions.setProfitabilities(body as ProfitabilitiesPayload))
      break
    }
    default:
      return []
  }
}
