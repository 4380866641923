import {
  StyledAlert,
  StyledCloseButton,
  StyledCloseIcon,
  StyledContainer,
  StyledLabel,
  StyledLink,
  StyledRatingContainer,
} from './styles/SnackBarRating.style'
import { useAppSelector } from '../../common/hooks'
import { dateFromTimestamp, isRatingDisabled } from '../../common/utils'
import { RatingStars } from '../molecules/RatingStars'
import { SyntheticEvent, useState } from 'react'
import { RatingPopover } from '../molecules/Invoices/RatingPopover'
import { dispatch } from '../../store'
import { ratingActions } from '../../store/slices/rating'
import { putRating } from '../../services/ratingApi'

export const SnackBarRating = () => {
  const rating = useAppSelector((state) => state.rating)
  const ratingHasValue = rating?.ratingValue !== null
  const [openPopover, setOpenPopover] = useState(false)
  const [modalAnchorEl, setModalAnchorEl] = useState<Element | null | undefined>(null)
  const [currentRating, setCurrentRating] = useState(rating.ratingValue)
  const [isRatingReadOnly, setIsRatingReadOnly] = useState(ratingHasValue)

  const isDisabled = isRatingDisabled(rating)
  const date = dateFromTimestamp(rating.creationDatetime)

  const handleChangeRating = async (
    event: SyntheticEvent<Element, Event>,
    newValue: number | null
  ) => {
    setModalAnchorEl(event.currentTarget.parentElement?.parentElement)
    if (newValue) {
      setCurrentRating(newValue)
      await putRating(rating.id, { rating: newValue })
    }
    setIsRatingReadOnly(true)
    setOpenPopover(true)
  }

  const handleClose = () => dispatch(ratingActions.setShown())

  const isRatingVisible = !isDisabled && !rating.shown && !ratingHasValue && rating.id != 0

  return (
    <>
      {isRatingVisible && (
        <>
          <StyledAlert>
            <StyledContainer>
              <StyledRatingContainer>
                <StyledLabel>Como você avalia sua última entrega?</StyledLabel>
                <RatingStars
                  isReadOnly={isRatingReadOnly}
                  disabled={false}
                  handleChangeRating={handleChangeRating}
                  currentRating={currentRating}
                  isInCard={false}
                  onClick={null}
                />
              </StyledRatingContainer>
              <StyledLink to={'/invoices'}>
                {`Ver detalhes da entrega de ${date.split(', ')[1]}`}
              </StyledLink>
            </StyledContainer>
            <StyledCloseButton onClick={handleClose}>
              <StyledCloseIcon />
            </StyledCloseButton>
          </StyledAlert>
          <RatingPopover
            openModal={openPopover}
            rating={rating}
            currentRating={currentRating}
            setCurrentRating={setCurrentRating}
            setOpenPopover={setOpenPopover}
            anchorEl={modalAnchorEl}
            handleCloseSnackBar={handleClose}
          />
        </>
      )}
    </>
  )
}
