import { clubbiStyled } from 'clubbi-ui'

export const StyledProductCard = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: '140px',

  [theme.breakpoints.up('md')]: {
    minHeight: '110px',
  },
}))

export const StyledProductDetailsContainer = clubbiStyled('div')<{
  quantityGetMoreForLess: boolean
}>(({ theme, quantityGetMoreForLess }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  gap: '6px',
  maxWidth: '220px',
  height: quantityGetMoreForLess ? '115px' : '75px',

  [theme.breakpoints.up('md')]: {
    gap: '14px',
    flex: 4,
    maxWidth: 'none',
  },
}))

export const StyledProductDetailsContent = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '6px',
  minHeight: '120px',

  [theme.breakpoints.up('md')]: {
    height: '90px',
    gap: '8px',
    flex: 4,
    minHeight: '110px',
  },
}))

export const StyledImage = clubbiStyled('img')(() => ({
  objectFit: 'contain',
  flex: 1,
}))

export const StyledProductDescriptionContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '5px',
  flex: 9,
  paddingRight: '34px',
  width: '100%',

  [theme.breakpoints.up('md')]: {
    paddingRight: '0',
  },
}))

export const StyledProductDescription = clubbiStyled('span')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  fontWeight: 600,
  lineHeight: '130.9%',

  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))

export const StyledPriceContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  gap: '4px',
  fontSize: theme.typography.fontSize2XS,
  fontWeight: 400,
  lineHeight: '125.9%',

  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))
export const StyledPackageQuantity = clubbiStyled('span')(({ theme }) => ({
  color: theme.palette.brandPurple[60],
}))

export const StyledPackagePrice = clubbiStyled('span')(({ theme }) => ({
  color: theme.palette.brandGrey[60],
}))

export const StyledUnitPrice = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,

  color: theme.palette.brandGrey[100],

  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))

export const StyledQtyPriceContainer = clubbiStyled('div')<{
  quantityGetMoreForLess: boolean
}>(({ theme, quantityGetMoreForLess }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  gap: '12px',
  marginTop: quantityGetMoreForLess ? '25px' : '0px',
  height: '120px',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flex: 2,
    paddingLeft: '2%',
    marginTop: quantityGetMoreForLess ? '40px' : '0px',
    height: '110px',
  },
}))

export const StyledTotalPriceContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '8px',
  fontSize: theme.typography.fontSize2XS,

  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.fontSizeXXS,
    paddingTop: '10px',
    margin: 0,
  },
}))

export const StyledTotalPrice = clubbiStyled('span')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  lineHeight: '112%',

  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.fontSizeXXS,
    width: '80px',
  },
}))

export const SyledProfitabilitiesContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '-4px',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    width: '100px',
    fontSize: '13px',
    margin: 0,
  },
}))

export const SyledProfitabilitiesDiv = clubbiStyled('span')<{
  isNegative: boolean
}>(({ theme, isNegative }) => ({
  whiteSpace: 'nowrap',
  lineHeight: '20px',
  fontSize: theme.typography.fontSize2XS,
  color: isNegative ? theme.palette.brandWarning[60] : theme.palette.brandGrey[60],
  fontWeight: 600,

  [theme.breakpoints.up('md')]: {
    fontSize: '13px',
    lineHeight: '112%',
  },
}))
