import { themeClubbi } from 'clubbi-ui'
import { ReactNode } from 'react'
import { StyledContainer } from './styles/FooterIcon.style'

interface Props {
  isSelected: boolean
  onClick?: () => void
  children: ReactNode
  text: string
}

export const FooterIcon = ({ isSelected, onClick, children, text }: Props) => {
  const color = isSelected ? themeClubbi.palette.brandPurple[60] : themeClubbi.palette.brandGrey[70]
  return (
    <StyledContainer color={color} onClick={onClick} isSelected={isSelected}>
      {children}
      <div>{text}</div>
    </StyledContainer>
  )
}
