import { clubbiStyled } from 'clubbi-ui'

export const StyledTitle = clubbiStyled('p')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '16px',
  marginBottom: '20px',
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    marginBottom: '0',
  },
}))
