import { Link } from 'react-router-dom'
import { Product } from '../../../store/data/types'
import { BadgePromotion } from './BadgePromotion/BadgePromotion'
import { StyledBannerCard, StyledContent, StyledProductImage } from './Banner.style'
import { getLowestUnitPrice } from '../../../common/productUtils'

interface BannerCardProps {
  product: Product
}

export const BannerCard = ({ product }: BannerCardProps) => {
  const { description, imageUrls } = product

  return (
    <>
      <Link to={`/search?query=${description}`}>
        <StyledBannerCard>
          <StyledContent>
            <StyledProductImage src={imageUrls.image300Px} alt={description} />
          </StyledContent>
          <BadgePromotion price={getLowestUnitPrice(product)} />
        </StyledBannerCard>
      </Link>
    </>
  )
}
