import { useHistory, useLocation } from 'react-router'
import { HomeIcon } from '../../atoms/HomeIcon'
import { StyledFooter, StyledIconContainer } from './styles/Footer.style'
import { OffersIcon } from '../../atoms/OffersIcon'
import { MissionProgress } from '../../molecules/MissionProgress'
import { useAppSelector } from '../../../common/hooks'
import { isMerchantBlocked } from '../../../common/utils'
import { missionProgressModalActions } from '../../../store/slices/missionProgressModal'
import { MissionsIcon } from '../../atoms/MissionsIcons'
import { dispatch } from '../../../store'
import { FooterIcon } from '../../molecules/Icons/FooterIcon'
import { CartIconWithBadge } from '../../molecules/Icons/CartIconWithBadge'
import { recommendedDrawerActions } from '../../../store/slices/recommendedDrawer'
import gtm from '../../../common/gtmTracker'
import { MissionProgressModalWrapper } from '../../molecules/styles/MissionProgress.style'

interface Props {
  handleDrawerToggle?: () => void
  offersIsOpen: boolean
}

export const Footer = ({ handleDrawerToggle, offersIsOpen }: Props) => {
  const history = useHistory()
  const location = useLocation()
  const [status, isLogged, merchantCode, isOpen, missions, clubberEmail] = useAppSelector(
    (state) => [
      state.session.status,
      !!state.session.merchantCode,
      state.session.merchantCode,
      state.missionProgressModal.isOpen,
      state.missions,
      state.session.clubberEmail,
    ]
  )

  const hasMission = !!missions?.length

  const isSelectedHome = location.pathname === '/' && !offersIsOpen && !isOpen
  const isSelectedCart = location.pathname === '/cart' && !offersIsOpen && !isOpen
  const isSelectedOffers = offersIsOpen
  const isMissionProgressModalOpen = isOpen

  const handleClickHome = () => {
    history.replace('/')
    if (offersIsOpen) {
      handleDrawerToggle!()
    }
    if (isMissionProgressModalOpen) {
      dispatch(missionProgressModalActions.setIsOpen(!isMissionProgressModalOpen))
    }
    gtm.triggerFooterClick(merchantCode, 'home', clubberEmail)
  }

  const handleClickOffers = () => {
    handleDrawerToggle!()
    if (isMissionProgressModalOpen) {
      dispatch(missionProgressModalActions.setIsOpen(!isMissionProgressModalOpen))
    }
    gtm.triggerFooterClick(merchantCode, 'offers', clubberEmail)
  }

  const handleMissionExpandButtonClick = () => {
    dispatch(missionProgressModalActions.setIsOpen(!isMissionProgressModalOpen))
    if (offersIsOpen) {
      handleDrawerToggle!()
    }
    gtm.triggerFooterClick(merchantCode, 'missions', clubberEmail)
  }

  const handleClickCart = () => {
    if (isLogged && !isMerchantBlocked(status)) {
      dispatch(recommendedDrawerActions.setIsOpen(true))
    }
    if (offersIsOpen) {
      handleDrawerToggle!()
    }
    if (isMissionProgressModalOpen) {
      dispatch(missionProgressModalActions.setIsOpen(!isMissionProgressModalOpen))
    }
    history.replace('/cart')
    gtm.triggerFooterClick(merchantCode, 'cart', clubberEmail)
  }

  return (
    <StyledFooter>
      <StyledIconContainer isSelected={isSelectedHome} hasMission={hasMission}>
        <FooterIcon isSelected={isSelectedHome} onClick={handleClickHome} text={'Início'}>
          <HomeIcon isSelected={isSelectedHome} />
        </FooterIcon>
      </StyledIconContainer>
      <StyledIconContainer isSelected={isSelectedOffers} hasMission={hasMission}>
        <FooterIcon isSelected={isSelectedOffers} onClick={handleClickOffers} text={'Ofertas'}>
          <OffersIcon isSelected={isSelectedOffers} />
        </FooterIcon>
      </StyledIconContainer>
      {hasMission && (
        <StyledIconContainer isSelected={isMissionProgressModalOpen} hasMission={hasMission}>
          <FooterIcon
            isSelected={isMissionProgressModalOpen}
            onClick={handleMissionExpandButtonClick}
            text={'Missões'}
          >
            <MissionsIcon isSelected={isMissionProgressModalOpen} />
          </FooterIcon>
        </StyledIconContainer>
      )}
      <StyledIconContainer isSelected={isSelectedCart} hasMission={hasMission}>
        <CartIconWithBadge isSelectedCart={isSelectedCart} handleClickCart={handleClickCart} />
      </StyledIconContainer>
      {isLogged && !isMerchantBlocked(status) && hasMission && (
        <MissionProgressModalWrapper>
          <MissionProgress />
        </MissionProgressModalWrapper>
      )}
    </StyledFooter>
  )
}
