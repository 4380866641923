import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'

import { useAppSelector } from '../../../common/hooks'
import { getPriceByMode } from '../../../common/productUtils'
import { CartChange, CartItem, Product } from '../../../store/data/types'
import { cartActions } from '../../../store/slices/cart'
import { formatNumber, quantityToCompleteComboCalc } from '../../../common/utils'
import { ComboBadge } from '../../molecules/PopupGetMoreForLess/ComboBadge'
import { CartItemInput } from '../../molecules/productItem/CartItemInput'
import { RootState } from '../../../store/reducers'
import { DeleteCartItem } from '../../molecules/productItem/DeleteCartItem'
import { GetMoreForLessInformations } from '../../molecules/getMoreForLessInformations/GetMoreForLessInformations'
import { Money } from '../../atoms'

import {
  StyledImage,
  StyledPackagePrice,
  StyledPackageQuantity,
  StyledPriceContainer,
  StyledProductCard,
  StyledProductDescription,
  StyledProductDescriptionContainer,
  StyledProductDetailsContainer,
  StyledProductDetailsContent,
  StyledQtyPriceContainer,
  StyledTotalPrice,
  StyledTotalPriceContainer,
  StyledUnitPrice,
  SyledProfitabilitiesContainer,
  SyledProfitabilitiesDiv,
} from './styles/CartProductsListItem.style'
import { getTotalPrice } from '../../../common/cardUtils'
import { themeClubbi, useMediaQuery } from 'clubbi-ui'

export interface CartProductsListItemProps {
  item: CartItem
  className?: string
}

const selectProduct = createSelector(
  (state: RootState) => state.cart,
  (_: RootState, { product }: { product: Product }) => product.id,
  (cart, id) => cart[id]?.quantity || 0
)

const ConnectedPrice = ({
  price,
  id,
  product,
}: {
  price: number
  id: string
  product: Product
}) => {
  const quantity = useAppSelector((state: RootState) => state.cart[id]?.quantity || 0)
  const totalPriceOutsidePromotion = price * quantity
  const totalPrice = +getTotalPrice(product, 0, quantity)
  const amount = product.supplierPrices[0].getMoreForLess ? totalPrice : totalPriceOutsidePromotion
  return <Money isBold amount={amount} />
}

export const CartProductsListItem = ({
  item,
  className = '',
  ...props
}: CartProductsListItemProps) => {
  const { isPackageMode, product } = item
  const price = getPriceByMode(isPackageMode, product)
  const { merchantCode, clubberEmail } = useAppSelector((state) => state.session)
  const isGetMoreForLessPromotion = product.supplierPrices[0].getMoreForLess
  const value = useSelector((state: RootState) => selectProduct(state, { product }))
  const dispatch = useDispatch()
  const [profitabilities, setProfitabilities] = useState<any>({})
  const isDesktop = useMediaQuery(themeClubbi.breakpoints.up('md'))

  const height = 50
  const imageURL = height > 120 ? product.imageUrls.image300Px : product.imageUrls.image120Px

  const onChange = useCallback(
    (change: CartChange) => {
      dispatch(
        cartActions.setQuantity({
          product,
          cartChange: change,
          merchantCode: merchantCode!,
          clubberEmail,
          section: 'cart',
        })
      )
    },
    [dispatch, product]
  )

  const quantityGetMoreForLess = !!quantityToCompleteComboCalc(item)

  const productsProfitabilities = useAppSelector((state) => state.profitabilities)

  useEffect(() => {
    if (productsProfitabilities.products) {
      const filteredProduct = productsProfitabilities.products.find(
        (product) => product[item.product.ean]
      )?.[item.product.ean]

      setProfitabilities(filteredProduct)
    }
  }, [productsProfitabilities.products])

  const Profitabilities: React.FC = () => (
    <>
      {profitabilities.marginByKg && profitabilities.lc1Percent && (
        <SyledProfitabilitiesContainer>
          <SyledProfitabilitiesDiv isNegative={profitabilities.marginByKg < 0}>{`${formatNumber(
            profitabilities.marginByKg
          )}/Kg`}</SyledProfitabilitiesDiv>
          <SyledProfitabilitiesDiv
            isNegative={profitabilities.lc1Percent < 0}
          >{`${profitabilities.lc1Percent}%`}</SyledProfitabilitiesDiv>
        </SyledProfitabilitiesContainer>
      )}
    </>
  )

  return (
    <StyledProductCard>
      <StyledProductDetailsContainer quantityGetMoreForLess={quantityGetMoreForLess}>
        {quantityGetMoreForLess && <ComboBadge item={item} />}
        <StyledProductDetailsContent>
          <StyledImage
            loading="lazy"
            decoding="async"
            src={imageURL}
            alt=""
            width={height}
            height={height}
          />
          <StyledProductDescriptionContainer>
            <StyledProductDescription>{product.description}</StyledProductDescription>
            {isPackageMode && (
              <StyledPriceContainer>
                <StyledPackageQuantity>
                  Caixa c/ {product.packageNumberOfItems}
                </StyledPackageQuantity>
                <StyledPackagePrice>
                  {'('}
                  <Money amount={price} />
                  {')'}
                </StyledPackagePrice>
              </StyledPriceContainer>
            )}
            <StyledUnitPrice>
              <Money
                amount={isPackageMode ? price / (product?.packageNumberOfItems ?? 1) : price}
              />
              {'/un'}
            </StyledUnitPrice>
            {isGetMoreForLessPromotion && (
              <GetMoreForLessInformations product={product} quantity={value} />
            )}
          </StyledProductDescriptionContainer>
        </StyledProductDetailsContent>
      </StyledProductDetailsContainer>
      <StyledQtyPriceContainer quantityGetMoreForLess={quantityGetMoreForLess}>
        {!isDesktop && clubberEmail && profitabilities && <Profitabilities />}
        <CartItemInput
          value={value}
          onChange={onChange}
          product={product}
          ctx={'cart'}
          isOfertao={!!product.inOfertao}
          isPackageMode={isPackageMode}
          variant="small"
        />
        <StyledTotalPriceContainer>
          <StyledTotalPrice>
            <ConnectedPrice price={price!} id={product.id} product={product} />
          </StyledTotalPrice>
          {isDesktop && clubberEmail && profitabilities && <Profitabilities />}
          <DeleteCartItem
            value={value}
            onChange={onChange}
            product={product}
            ctx={'cart'}
            isOfertao={!!product.inOfertao}
            isPackageMode={isPackageMode}
          />
        </StyledTotalPriceContainer>
      </StyledQtyPriceContainer>
    </StyledProductCard>
  )
}
