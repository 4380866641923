import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CategoryBanner, themeClubbi } from 'clubbi-ui'
import { LinkBackHome } from '../atoms'
import { StyledMain, StyleContent } from './styles/Section.style'
import SubCategoriesTags from '../molecules/subCategoriesTags/SubCategoriesTags'
import { StyledLinkToHome } from '../atoms/styles/LinkBackHomeDiv.style'
import { getProductsSectionByCategory } from '../../services/productApi'
import { categoryProductsToGroups, categoryRoutes } from '../../common/categoryUtils'
import { useAppSelector } from '../../common/hooks'
import { urlImages } from '../../common/constants'
import { LinkData, Offer } from '../../store/data/types'
import { ProductsList } from '../organisms/product/ProductsList'
import { Spinner } from '../atoms/Spinner'

interface SectionProps {
  routes: LinkData[]
  groups: Offer[]
}

export const Section = () => {
  const { section } = useParams<{ section: string }>()
  const [categories, { merchantCode, regionId }] = useAppSelector((state) => [
    state.categories,
    state.session,
  ])
  const [dataSection, setDataSection] = useState<SectionProps | null>()
  const [error, setError] = useState<any>(null)

  const category = categories.find(({ slug }) => slug === section)

  const fetchSection = async () => {
    const response = await getProductsSectionByCategory(section)

    return {
      groups: response.categories?.map(categoryProductsToGroups) ?? [],
      routes: categoryRoutes(response),
    }
  }

  useEffect(() => {
    fetchSection().then(setDataSection).catch(setError)
  }, [section, merchantCode, regionId])

  if (error) {
    throw error
  }

  return (
    <StyledMain>
      <StyledLinkToHome>
        <LinkBackHome />
      </StyledLinkToHome>
      <StyleContent>
        {category && (
          <CategoryBanner
            title={category.name}
            description={'Aproveite as melhores ofertas para o seu mercadinho'}
            image={`${urlImages}/${section}`}
            background={themeClubbi.palette.brandInfo[20]}
          />
        )}
      </StyleContent>
      <SubCategoriesTags section={section} />
      {dataSection ? (
        <ProductsList groups={dataSection.groups} context={['section']} />
      ) : (
        <Spinner />
      )}
    </StyledMain>
  )
}
