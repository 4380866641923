import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Spinner, themeClubbi, useMediaQuery } from 'clubbi-ui'
import { useInView } from 'react-intersection-observer'

import gtm from '../../../common/gtmTracker'
import { CUSTOM_PRODUCTS_QUANTITY_PER_PAGE } from '../../../common/constants'
import { useAppSelector } from '../../../common/hooks'
import { Product } from '../../../store/data/types'
import { ProductsListAvaActions } from '../../../store/slices/ProductsListAva'
import { BannerCard } from './BannerCard'
import { getCustomProducts } from '../../../services/productApi'
import {
  StyledContainer,
  StyledMainBanner,
  StyledBannerTitle,
  StyledContainerCards,
  StyledTopBanner,
  StyleExploreButton,
  StyledSpinner,
  StyledImg,
} from './Banner.style'

export const Banner = () => {
  const [products, setProducts] = useState<Product[] | []>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { inView, ref } = useInView({ threshold: 0.6 })

  const [merchantCode] = useAppSelector((state) => [state.session.merchantCode])
  const clubberEmail = useAppSelector((state) => state.session.clubberEmail)

  const dispatch = useDispatch()

  const matches = useMediaQuery(themeClubbi.breakpoints.up('sm'))

  const size = matches ? 8 : 4

  const getProducts = async () => {
    setLoading(true)
    const customProducts = await getCustomProducts(CUSTOM_PRODUCTS_QUANTITY_PER_PAGE)
    setProducts(customProducts)
    setLoading(false)
  }

  useEffect(() => {
    getProducts()
  }, [merchantCode])

  dispatch(ProductsListAvaActions.setProductsListAva(products))

  useEffect(() => {
    if (inView) {
      gtm.triggerImpressionSearchBanner(merchantCode, clubberEmail)
    }
  }, [inView])

  const handleClick = () => {
    gtm.triggerSearchBannerClick(merchantCode, clubberEmail)
  }

  return (
    <StyledContainer ref={ref}>
      <StyledMainBanner>
        <StyledTopBanner>
          <StyledBannerTitle>💰 Mais economia</StyledBannerTitle>
          <Link to="/mais-economia" onClick={handleClick}>
            <StyleExploreButton>
              {'ver tudo '}
              <StyledImg src={'/img/vector-button.png'} alt=">" />
            </StyleExploreButton>
          </Link>
        </StyledTopBanner>
        <StyledContainerCards>
          {products.length && !loading ? (
            products
              .slice(0, size)
              .map((product, index) => <BannerCard key={index} product={product} />)
          ) : (
            <StyledSpinner>
              <Spinner />
            </StyledSpinner>
          )}
        </StyledContainerCards>
      </StyledMainBanner>
    </StyledContainer>
  )
}
