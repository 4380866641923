import { splitTestShippingTypesReducer } from './../slices/testShippingTypes'
import { combineReducers } from '@reduxjs/toolkit'
import { cartReducer } from '../slices/cart'
import { sessionReducer } from '../slices/session'
import { algoliaEventReducer } from '../slices/algoliaEvents'
import { searchReducer } from '../slices/search'
import { popupReducer } from '../slices/popup'
import { messagesReducer } from '../slices/messages'
import { blockedBannerReducer } from '../slices/blockedBanner'
import { purchaseReducer } from '../slices/purchase'
import { categoriesReducer } from '../slices/categories'
import { bannersReducer } from '../slices/banners'
import { notificationsReducer } from '../slices/notifications'
import { creditReducer } from '../slices/credit'
import { welcomeOffersReducer } from '../slices/welcome'
import { productsSectionReducer } from '../slices/productsSection'
import { missionsReducer } from '../slices/missions'
import { recommendedDrawerReducer } from '../slices/recommendedDrawer'
import { recommendedSearchReducer } from '../slices/recommendedSearch'
import { tagCategoriesMenuReducer } from '../slices/tagCategoriesMenu'
import { searchListReducer } from '../slices/searchList'
import { ProductsListAvaReducer } from '../slices/ProductsListAva'
import { missionProgressModalReducer } from '../slices/missionProgressModal'
import { taxBenefitEansReducer } from '../slices/taxBenefitEans'
import { ratingReducer } from '../slices/rating'
import { allowedPurchaseBannerReducer } from '../slices/allowedPurchase'
import { profileReducer } from '../slices/profile'
import { shippingInfosReducer } from '../slices/ShippingInfos'
import { salesFlyerReducer } from '../slices/salesFlyer'
import { getMoreForLessReducer } from '../slices/getMoreForLess'
import { profitabilitiesReducer } from '../slices/profitabilities'

export const createRootReducer = () =>
  combineReducers({
    cart: cartReducer,
    session: sessionReducer,
    algoliaEvent: algoliaEventReducer,
    popup: popupReducer,
    search: searchReducer,
    messages: messagesReducer,
    categories: categoriesReducer,
    blockedBanner: blockedBannerReducer,
    purchase: purchaseReducer,
    banners: bannersReducer,
    credit: creditReducer,
    notifications: notificationsReducer,
    welcomeOffers: welcomeOffersReducer,
    productsSection: productsSectionReducer,
    missions: missionsReducer,
    recommendedDrawer: recommendedDrawerReducer,
    recommendedSearch: recommendedSearchReducer,
    tagCategoriesMenu: tagCategoriesMenuReducer,
    searchList: searchListReducer,
    productsListAva: ProductsListAvaReducer,
    missionProgressModal: missionProgressModalReducer,
    taxBenefitEans: taxBenefitEansReducer,
    rating: ratingReducer,
    allowedPurchase: allowedPurchaseBannerReducer,
    profile: profileReducer,
    splitTestShippingTypes: splitTestShippingTypesReducer,
    shippingInfos: shippingInfosReducer,
    salesFlyer: salesFlyerReducer,
    getMoreForLess: getMoreForLessReducer,
    profitabilities: profitabilitiesReducer,
  })

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>
