import { themeClubbi } from 'clubbi-ui'

export interface Seasonality {
  name: string
  imageHeaderDesktop?: string
  imageHeaderMobile?: string
  imageFlyer?: string
  imageFlyerFooter?: string
  backgroundColor?: string
  headerColor?: string
  hoverColor?: string
  footerColor?: string
  iconColor?: string
  shadow?: string
  dateTimeToStart: Date
  dateTimeToExpire: Date
}

export const seasonalities: Seasonality[] = [
  {
    name: 'dia dos namorados',
    imageHeaderDesktop: '/img/header-desktop.png',
    imageHeaderMobile: '/img/header-mobile.png',
    imageFlyer: '/img/flyer-dia-dos-namorados.png',
    imageFlyerFooter: '/img/flyer-footer-dia-dos-namorados.png',
    backgroundColor: '#AE0001',
    headerColor: '#760000',
    hoverColor: themeClubbi.palette.brandAlert[100],
    footerColor: themeClubbi.palette.brandYellow[60],
    iconColor: themeClubbi.palette.brandWhite.main,
    shadow: '1px 1px 2px black,0 0 1em white,0 0 0.1em white',
    dateTimeToStart: new Date(2024, 5, 6),
    dateTimeToExpire: new Date(2024, 5, 13),
  },
]

export const standard: Seasonality = {
  name: 'default',
  imageHeaderDesktop: '',
  imageHeaderMobile: '',
  imageFlyer: '/img/flyer-header.png',
  imageFlyerFooter: '/img/flyer-footer.png',
  backgroundColor: '#7B35C9',
  headerColor: themeClubbi.palette.brandPurple[60],
  hoverColor: themeClubbi.palette.brandPurple[80],
  footerColor: '#F4BA01',
  iconColor: themeClubbi.palette.brandPurple[60],
  shadow: '',
  dateTimeToStart: new Date(2020, 10, 21),
  dateTimeToExpire: new Date(2060, 10, 21),
}

export const getSeasonStyle = (): Seasonality => {
  const today = new Date()
  const season = seasonalities.find(
    (event) => today >= event.dateTimeToStart && today <= event.dateTimeToExpire
  )
  return season ? season : standard
}
