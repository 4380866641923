import { StyledBadge, StyledBadgeText, StyledBadgePrice } from './Badge.style'

interface Badge {
  price: number
}

export const BadgePromotion = ({ price }: Badge) => {
  const formattedPrice = price.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  return (
    <StyledBadge>
      <StyledBadgeText>a partir de:</StyledBadgeText>
      <StyledBadgePrice>{` R$ ${formattedPrice}`}</StyledBadgePrice>
    </StyledBadge>
  )
}
