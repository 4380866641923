import { useCallback, useEffect, useMemo, useState } from 'react'
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches'
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions'
import { getAlgoliaResults } from '@algolia/autocomplete-js'
import algoliasearch from 'algoliasearch/lite'
import { useHistory } from 'react-router-dom'
import '@algolia/autocomplete-theme-classic'
import { useAppSelector } from '../../../common/hooks'

import {
  ALGOLIA_APP_ID,
  ALGOLIA_APP_KEY,
  REACT_APP_ALGOLIA_DB,
  REACT_APP_INDEX_NAME,
} from '../../../common/AlgoliaAutocomplete.config'
import { ALGOLIA_RESULTS, HITS_PER_PAGE, SEARCH_RESULTS_QUANTITY } from '../../../common/constants'
import { Product } from '../../../store/data/types'
import { getCustomProducts } from '../../../services/productApi'
import { RecommendedProductsAlgolia } from '../recommendedCorridor/RecommendedSearchBarProducts'
import { HeaderLogo } from './headerIcons/HeaderLogo'
import { Autocomplete } from '../Algolia/Autocomplete'
import { ShoppingListButtonNew } from '../../atoms/ShoppingListButtonNew'

import {
  StyledAlgoliaItem,
  StyledHr,
  StyledSearchBarSectionsTitle,
} from '../recommendedCorridor/styles/RecommendedSearchBarProducts.style'
import {
  StyledHeaderMain,
  StyledIconsHeader,
  StyledLinkHeader,
  StyledSearchBar,
} from './style/Header.style'
import { debounced } from '../../../common/utils'
import { ProfileButton } from '../profile/ProfileButton'

export const HeaderMobile = () => {
  const isLogged = useAppSelector((state) => !!state.session.merchantCode)
  const { merchantCode } = useAppSelector((state) => state.session)

  const [products, setProducts] = useState<Product[] | []>([])

  const history = useHistory()

  const searchClient = algoliasearch(ALGOLIA_APP_ID!, ALGOLIA_APP_KEY!)

  const getProducts = async () => {
    const customProducts = await getCustomProducts(SEARCH_RESULTS_QUANTITY)
    setProducts(customProducts)
  }

  useEffect(() => {
    getProducts()
  }, [history.location.pathname, history.location.search])

  const onSubmit = useCallback(({ state }: { state: any; setIsOpen: any }) => {
    const detachedModeSearchPanel = document.querySelector('.aa-Detached')
    if (detachedModeSearchPanel) {
      detachedModeSearchPanel.classList.remove('aa-Detached')
    }
    if (state.query) {
      history.replace({
        pathname: '/search',
        search: new URLSearchParams({ query: state.query }).toString(),
      })
    }
  }, [])

  const plugins = useMemo(() => {
    const recentSearches = createLocalStorageRecentSearchesPlugin({
      key: 'searches',
      limit: 3,
      transformSource({ source }) {
        return {
          ...source,
          onSelect({ state }) {
            state.isOpen = false
            if (state.query) {
              history.replace({
                pathname: '/search',
                search: new URLSearchParams({ query: state.query }).toString(),
              })
            }
          },
          templates: {
            ...source.templates,
            header() {
              return (
                <>
                  <StyledHr />
                  <StyledSearchBarSectionsTitle>BUSCAS RECENTES</StyledSearchBarSectionsTitle>
                </>
              )
            },
          },
        }
      },
    })

    const querySuggestions = createQuerySuggestionsPlugin({
      searchClient,
      indexName: REACT_APP_INDEX_NAME!,
      getSearchParams() {
        return recentSearches.data!.getAlgoliaSearchParams({
          hitsPerPage: HITS_PER_PAGE,
        })
      },

      transformSource({ source }) {
        return {
          ...source,
          sourceId: 'querySuggestionsPlugin',
          onSelect({ state, setIsOpen }) {
            setIsOpen(false)
            state.isOpen = false
            if (state.query) {
              history.replace({
                pathname: '/search',
                search: new URLSearchParams({ query: state.query }).toString(),
              })
            }
          },
          getItems(params) {
            if (!params.state.query) {
              return []
            }
            const items = source.getItems(params)
            return items
          },
          templates: {
            ...source.templates,
            header() {
              return (
                <>
                  <StyledHr />
                  <StyledSearchBarSectionsTitle>SUGESTÕES</StyledSearchBarSectionsTitle>
                </>
              )
            },
          },
        }
      },
    })
    return [querySuggestions, recentSearches]
  }, [])

  return (
    <StyledHeaderMain>
      <StyledLinkHeader to="/">
        <HeaderLogo />
      </StyledLinkHeader>
      <StyledSearchBar className="autocomplete">
        <Autocomplete
          translations={{ detachedCancelButtonText: 'Fechar' }}
          plugins={plugins}
          onSubmit={onSubmit}
          openOnFocus={true}
          insights={true}
          placeholder="Buscar produto"
          enterKeyHint={'enter'}
          getSources={({ query }: any) =>
            debounced([
              {
                sourceId: 'products',
                getItems() {
                  return getAlgoliaResults({
                    searchClient,
                    queries: [
                      {
                        indexName: REACT_APP_ALGOLIA_DB!,
                        query,
                        params: {
                          hitsPerPage: ALGOLIA_RESULTS,
                        },
                      },
                    ],
                  })
                },
                templates: {
                  item() {
                    return (
                      <StyledAlgoliaItem>
                        {products ? (
                          <RecommendedProductsAlgolia
                            history={history}
                            products={products}
                            merchantCode={merchantCode}
                          />
                        ) : (
                          <></>
                        )}
                      </StyledAlgoliaItem>
                    )
                  },
                },
              },
            ])
          }
        />
      </StyledSearchBar>

      <StyledIconsHeader>
        {isLogged && <ShoppingListButtonNew />}
        <ProfileButton />
      </StyledIconsHeader>
    </StyledHeaderMain>
  )
}
