import { useState } from 'react'
import { ConfirmationModal } from './ConfirmationModal'
import { ProductsDetails } from './ProductsDetails'
import {
  StyledCancelBtn,
  StyledCardHeader,
  StyledCreationTag,
  StyledHeaderCell,
  StyledLeftHeaderCell,
  StyledMessage,
  StyledOpenOrderCard,
  StyledOrderHeader,
  StyledOrderTag,
  StyledQuantityHeaderCell,
  StyledTotalPriceFooter,
  StyledTotalPriceLabelCell,
  StyledTotalPriceValueCell,
  StyledVoidHeaderCell,
} from './styles/OpenOrder.style'

export interface OrderItem {
  productId: number
  quantity: number
  unitPrice: number | null
  productPackageQtd: number
  totalPrice: number
  productDescription: string
  category: string
  isMultiPackage: boolean
  creationDatetime: Date | string
  id: number
  isCancelled: boolean | null
}

export type FetchOrdersFunction = () => Promise<void>

interface Props {
  productBuckets: OrderItem[]
  orderId: number
  creationDatetime: Date | string
  fetchOrders: FetchOrdersFunction
}

export const OpenOrderDetails = ({
  orderId,
  creationDatetime,
  productBuckets,
  fetchOrders,
}: Props) => {
  const finalTotalPrice = productBuckets.reduce((acc: number, item: OrderItem) => {
    if (item.isCancelled) return acc
    return acc + item.totalPrice
  }, 0)
  const [showModal, setShowModal] = useState(false)

  return (
    <StyledOpenOrderCard>
      <ConfirmationModal
        showModal={showModal}
        setShowModal={setShowModal}
        cancellationId={orderId}
        isItem={false}
        fetchOrders={fetchOrders}
      />
      <StyledCardHeader>
        <StyledMessage>
          <StyledOrderTag>Pedido: {orderId}</StyledOrderTag>
          <StyledCreationTag>Emissão: {creationDatetime.toLocaleString('pt-BR')}</StyledCreationTag>
        </StyledMessage>
        <StyledCancelBtn
          label="cancelar"
          typeButton="outline"
          size="medium"
          onClick={() => setShowModal(true)}
        />
      </StyledCardHeader>

      <StyledOrderHeader className="border-outside">
        <StyledLeftHeaderCell>Produto</StyledLeftHeaderCell>
        <StyledQuantityHeaderCell>Qtde</StyledQuantityHeaderCell>
        <StyledHeaderCell> Valor Unit.</StyledHeaderCell>
        <StyledHeaderCell> Total</StyledHeaderCell>
        <StyledVoidHeaderCell />
      </StyledOrderHeader>

      <ProductsDetails productBuckets={productBuckets} fetchOrders={fetchOrders} />

      <StyledTotalPriceFooter>
        <StyledTotalPriceLabelCell>Total </StyledTotalPriceLabelCell>
        <StyledTotalPriceValueCell data-testid={`finalTotalPrice-${orderId}`}>
          {finalTotalPrice.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </StyledTotalPriceValueCell>
        <StyledVoidHeaderCell />
      </StyledTotalPriceFooter>
    </StyledOpenOrderCard>
  )
}
