import { WhatsAppIcon } from 'clubbi-ui'
import React from 'react'
import { whatsappHelpCXLinkGenerator } from '../../../common/constants'
import { useAppSelector } from '../../../common/hooks'
import {
  StyledContainer,
  StyledP,
  StyledSupportText,
  StyledHelpLink,
  StyledLinkLabel,
} from './styles/ContactMessage.style'

export const ContactMessage = () => {
  const merchantCode = useAppSelector((state) => state.session.merchantCode)
  return (
    <StyledContainer>
      <StyledP>
        Fique atento ao seu celular para mensagens e ligações do nosso time! Para garantir a
        comunicação, pedimos que salve o contato do nosso suporte: <strong>(21) 99886-8478</strong>
      </StyledP>
      <StyledSupportText>Se você tiver alguma dúvida, contate-nos pelo WhatsApp:</StyledSupportText>
      <StyledHelpLink
        href={whatsappHelpCXLinkGenerator(merchantCode)}
        target={'_blank'}
        rel="noreferrer"
      >
        <WhatsAppIcon color="white" />
        <StyledLinkLabel>Suporte Clubbi</StyledLinkLabel>
      </StyledHelpLink>
    </StyledContainer>
  )
}
