import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'

import { Rating as RatingType } from '../../../store/data/types'
import { RatingStars } from '../RatingStars'
import {
  StyledEvaluationLabel,
  StyledRatingModule,
  StyledCardHeader,
} from './styles/RatingModule.style'
import { RatingPopover } from './RatingPopover'
import { putRating } from '../../../services/ratingApi'
import { isRatingDisabled } from '../../../common/utils'

export const RatingModule = ({ rating }: { rating: RatingType }) => {
  const [openPopover, setOpenPopover] = useState(false)
  const [modalAnchorEl, setModalAnchorEl] = useState<Element | null>(null)
  const [currentRating, setCurrentRating] = useState(rating.ratingValue)
  const [isRatingReadOnly, setIsRatingReadOnly] = useState(rating?.ratingValue !== null)
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  const submitRating = useCallback(async () => {
    if ((hasMounted || openPopover) && currentRating !== null) {
      await putRating(rating.id, { rating: currentRating })
    }
  }, [currentRating, rating.id])

  useEffect(() => {
    submitRating()
  }, [submitRating])

  const handleChangeRating = (event: SyntheticEvent<Element, Event>, newValue: number | null) => {
    if (newValue) {
      setCurrentRating(newValue)
    }
    setIsRatingReadOnly(true)
    setModalAnchorEl(event.currentTarget.parentElement)
    setOpenPopover(true)
  }

  const evaluationLabel = <StyledEvaluationLabel>Como avalia esta entrega:</StyledEvaluationLabel>

  const isEvaluationCompleted = isRatingDisabled(rating) || isRatingReadOnly

  return (
    <StyledRatingModule>
      <StyledCardHeader>
        {(!isEvaluationCompleted || openPopover) && evaluationLabel}
        <RatingStars
          isReadOnly={isRatingReadOnly}
          disabled={isRatingDisabled(rating) && !isRatingReadOnly}
          handleChangeRating={handleChangeRating}
          currentRating={currentRating}
          isInCard={false}
          onClick={null}
        />
      </StyledCardHeader>
      <RatingPopover
        openModal={openPopover}
        rating={rating}
        currentRating={currentRating}
        setCurrentRating={setCurrentRating}
        setOpenPopover={setOpenPopover}
        anchorEl={modalAnchorEl}
      />
    </StyledRatingModule>
  )
}
