import { HomeProps } from '../../../templates/Home'
import { SectionRow } from '../SectionRow'
import { StyledList } from './styles/ProductsList.styles'

export const ProductsList = ({ groups, seeMoreCategories = true, context }: HomeProps) => {
  return (
    <StyledList>
      {groups.map((offer, index) => {
        const section = { ...offer }
        if (!section.context) {
          section.context = section.link.href
        }
        if (section.context && section.context !== 'missions') {
          return (
            <SectionRow
              key={index}
              products={section.products}
              seeMoreLink={section.link.href}
              label={section.name}
              seeMoreCategories={seeMoreCategories}
              context={
                section.context !== undefined
                  ? [...(context ?? []), section.context ?? '']
                  : context
              }
            />
          )
        }
        return null
      })}
    </StyledList>
  )
}
