import { clubbiStyled as styled, Card } from 'clubbi-ui'

export const StyledCardWrapper = styled('div')({
  fontFamily: 'Baloo2',
})

export const StyledCard = styled(Card)<{
  cardTitle: string | undefined
}>(({ theme, cardTitle }) => ({
  position: 'relative',
  borderRadius: cardTitle ? '0px 0px 4px 4px' : '4px',
  height: '310px',
  width: '160px',
  padding: '0px 8px 8px 8px',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',

  background: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    height: '410px',
    width: '248px',
    padding: '0px 20px 14px 20px',
  },
}))

export const StyledMediaAndBadgeContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  minHeight: '88px',
  width: '144px',

  background: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    minHeight: '139px',
    width: '210px',
  },
}))

export const StyledProductImage = styled('img')(({ theme }) => ({
  cursor: 'pointer',
  maxHeight: '72px',
  width: 'auto',
  maxWidth: '120px',
  alignSelf: 'center',
  margin: '7px',

  [theme.breakpoints.up('sm')]: {
    height: '120px',
    margin: '0px',
    maxHeight: '120px',
    maxWidth: '200px',
  },
}))

export const StyledCardTitle = styled('p')(({ theme }) => ({
  cursor: 'pointer',
  fontSize: theme.typography.fontSize2XS,
  lineHeight: '13px',
  width: '144px',
  minHeight: '39px',
  maxHeight: '39px',
  padding: 0,
  margin: '8px 0',

  overflow: 'hidden',
  '-webkit-overflow': 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': '3',
  lineClamp: '3',
  '-webkit-box-orient': 'vertical',

  background: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXS,
    lineHeight: '17px',
    minHeight: '51px',
    maxHeight: '51px',
    width: '210px',
    margin: '8px 0 16px 0',
  },
}))

export const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '135px',
  width: '100%',

  background: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    minHeight: '160px',
  },
}))

export const StyledInfosSpace = styled('div')(({ theme }) => ({
  width: '162px',
  height: '22px',
  opacity: 1,

  [theme.breakpoints.up('sm')]: {
    width: '248px',
    height: '26px',
  },
}))
