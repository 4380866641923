import { clubbiStyled, CardMedia } from 'clubbi-ui'

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
}))

export const StyledMainBanner = clubbiStyled('div')(({ theme }) => ({
  width: '90%',
  maxheight: '400px',
  backgroundColor: theme.palette.brandWarning[50],
  borderRadius: '6px',
  padding: '24px',

  [theme.breakpoints.up('md')]: {
    width: '766px',

    maxHeight: '850px',
  },

  [theme.breakpoints.between('sm', 'md')]: {
    width: '766px',
    maxHeight: '850px',
  },

  [theme.breakpoints.down(361)]: {
    width: '98%',
  },
}))

export const StyledTitle = clubbiStyled(CardMedia)(({ theme }) => ({
  maxHeight: '120px',
  width: 'auto',
  alignSelf: 'center',
  margin: '7px',
}))

export const StyledBannerCard = clubbiStyled('div')(({ theme }) => ({
  width: '132px',
  height: '135px',
  backgroundColor: theme.palette.brandWhite.main,
  borderRadius: '6px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '10px',
  [theme.breakpoints.up('sm')]: {
    width: '166px',
    gap: '20px',
    height: '240px',
  },
}))

export const StyledProductImage = clubbiStyled('img')(({ theme }) => ({
  height: '70px',
  width: '80px',
  [theme.breakpoints.up('sm')]: {
    height: '100px',
    width: '120px',
  },
}))

export const StyledContent = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px',
}))

export const StyledBannerTitle = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXS,
  fontWeight: 700,
  marginBottom: '6px',
  color: theme.palette.brandWhite.main,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSize2XL,
  },
}))

export const StyledContainerCards = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '10px',
}))

export const StyledTopBanner = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '10px',
}))

export const StyleExploreButton = clubbiStyled('div')(({ theme }) => ({
  color: theme.palette.brandWhite.main,
  fontWeight: 700,
}))

export const StyledSpinner = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '70px',
  [theme.breakpoints.up('sm')]: {
    marginTop: '10px',
    height: '460px',
  },
}))

export const StyledImg = clubbiStyled('img')({
  margin: '-1.7px  10px 0px 2px',
})
