import { clubbiStyled, Grid } from 'clubbi-ui'

export const StyledContainerMain = clubbiStyled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '40px',
  },
}))

export const StyledContainer = clubbiStyled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: '20px',
  },
}))

export const StyledClickableText = clubbiStyled('button')(({ theme }) => ({
  background: 'none',
  border: 'none',
  color: theme.palette.brandInfo[80],
  '&:hover': {
    background: 'none',
    textDecoration: 'underline',
  },
}))

export const StyledMissionWarning = clubbiStyled('div')(({ theme }) => ({
  height: '100px',
  width: '100%',
  marginTop: '5px',
  background: theme.palette.brandWarning[10],
  border: `0.5px solid ${theme.palette.brandWarning[50]}`,
  borderRadius: '6px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '15px',
  fontSize: theme.typography.fontSize2XS,
  marginBottom: '15px',

  '.eclipse': {
    minWidth: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: theme.palette.brandWarning[50],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
  },

  '.warning-description': {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },

  [theme.breakpoints.up('sm')]: {
    marginTop: '20px',
  },

  [theme.breakpoints.down('sm')]: {
    button: {
      marginTop: '-19px',
      marginLeft: '-5px',
    },
  },
}))

export const StyledMissionContainer = clubbiStyled(Grid)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})

export const StyledDiv = clubbiStyled('div')(() => ({
  height: '40px',
  background: 'transparent',
}))

export const StyledMobileGrid = clubbiStyled(Grid)(() => ({
  width: '98%',
}))
