import { Switch } from 'clubbi-ui'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '../../../common/hooks'
import { salesFlyerActions } from '../../../store/slices/salesFlyer'

import { StyledContainerClubberInfos, StyledHr, StyledSwitch } from './style/ClubberSection.style'

export const ClubberSection = () => {
  const dispatch = useDispatch()
  const { clubberEmail } = useAppSelector((state) => state.session)
  const { modeGenerator } = useAppSelector((state) => state.salesFlyer)

  const handleChange = () => {
    dispatch(salesFlyerActions.setModeGenerator(!modeGenerator))
  }

  return (
    <StyledContainerClubberInfos>
      <span>Você está acessando como:</span>
      <strong>{clubberEmail}</strong>
      <StyledHr />
      <StyledSwitch>
        Gerador de Encartes
        <Switch
          color="success"
          checked={modeGenerator}
          onChange={handleChange}
          disabled={!clubberEmail}
        />
      </StyledSwitch>
    </StyledContainerClubberInfos>
  )
}
