import { clubbiStyled } from 'clubbi-ui'

export const StyledBadge = clubbiStyled('button')(({ theme }) => ({
  background: theme.palette.brandPurple[60],
  border: '2px solid',
  borderRadius: '10px',
  color: 'white',
  fontSize: theme.typography.fontSize2XS,
  lineHeight: '1',
  fontWeight: '700',
  position: 'relative',
  padding: '8px',
  width: '70%',
  gap: '7px',
}))

export const StyledBadgeText = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSize4XS,
}))

export const StyledBadgePrice = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSize3XS,
  fontWeight: 700,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXS,
  },
}))
