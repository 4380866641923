import { FetchOrdersFunction, OrderItem } from './OpenOrderDetails'
import {
  StyledBottomRow,
  StyledDescription,
  StyledDescriptionCell,
  StyledEan,
  StyledIconCell,
  StyledProduct,
  StyledTopRow,
  StyledValueCell,
  StyledWrapElement,
} from './styles/SectionDetailRow.style'
import { Icon } from '../../atoms'
import { IconButton } from 'clubbi-ui'
import { ConfirmationModal } from './ConfirmationModal'
import { useState } from 'react'
import { QuantityCell } from './QuantityCell'

function DescriptionCell({ product }: { product: OrderItem }) {
  const { productId, productDescription, isCancelled } = product || {}
  return (
    <StyledDescriptionCell>
      <StyledDescription> {productDescription} </StyledDescription>
      <StyledEan isCancelled={isCancelled}>{`EAN: ${productId}`}</StyledEan>
    </StyledDescriptionCell>
  )
}

export function SectionDetailRow({
  product,
  isMobile,
  fetchOrders,
}: {
  product: OrderItem
  isMobile: boolean
  fetchOrders: FetchOrdersFunction
}) {
  const { productId, isCancelled, id, unitPrice, totalPrice } = product || {}
  const [showModal, setShowModal] = useState(false)
  return (
    <StyledWrapElement>
      <ConfirmationModal
        showModal={showModal}
        setShowModal={setShowModal}
        cancellationId={id}
        isItem
        fetchOrders={fetchOrders}
      />
      <StyledProduct isCancelled={isCancelled} data-testid={`section-detail-item-${productId}`}>
        <StyledTopRow>
          <DescriptionCell product={product} />
          {isMobile && (
            <StyledIconCell>
              {!isCancelled && (
                <IconButton onClick={() => setShowModal(true)}>
                  <Icon.DeleteIcon size={'11px'} fill="black" ariaLabel="deletar produto" />
                </IconButton>
              )}
            </StyledIconCell>
          )}
        </StyledTopRow>
        <StyledBottomRow>
          <QuantityCell product={product} fetchOrders={fetchOrders} />
          <StyledValueCell>
            {Number(unitPrice).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </StyledValueCell>
          <StyledValueCell>
            {totalPrice.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </StyledValueCell>
          {!isMobile && (
            <StyledIconCell>
              {!isCancelled && (
                <IconButton onClick={() => setShowModal(true)}>
                  <Icon.DeleteIcon size={'14px'} fill="black" ariaLabel="deletar produto" />
                </IconButton>
              )}
            </StyledIconCell>
          )}
        </StyledBottomRow>
      </StyledProduct>
    </StyledWrapElement>
  )
}
