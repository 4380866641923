import { clubbiStyled } from 'clubbi-ui'

export const StyledCartCardContainer = clubbiStyled('div')<{ removeMargin: boolean }>(
  ({ theme, removeMargin }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '18px',
    width: '100%',
    borderRadius: '4px',
    marginTop: removeMargin ? '0' : '10px',

    backgroundColor: theme.palette.brandWhite.main,

    [theme.breakpoints.up('md')]: {
      marginTop: removeMargin ? '0' : '25px',
    },
  })
)

export const StyledTitle = clubbiStyled('p')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '109.9%',
  textAlign: 'left',
  marginTop: '8px',
  marginBottom: '20px',

  color: theme.palette.brandBlack.main,

  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    marginBottom: '28px',
  },
}))

export const StyledEmptyCartMessage = clubbiStyled('span')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  lineHeight: '109.9%',

  color: theme.palette.brandGrey[100],

  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))

export const StyledCartProductsList = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',

  [theme.breakpoints.up('md')]: {
    gap: '18px',
  },
}))
