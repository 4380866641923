import { clubbiStyled, ScrollButton } from 'clubbi-ui'

export const StyledContainerOfertaoBox = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  marginTop: '10px',
  paddingLeft: '6px',

  [theme.breakpoints.up('md')]: {
    padding: '0 28px 0 34px',
    margin: '0 0 48px 0',
  },
}))

export const StyledContentOfertaoBox = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  width: '95%',
  borderRadius: '8px',
  padding: '25px 0px 25px 20px',
  gap: '10px',

  backgroundColor: theme.palette.brandYellow[40],

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    width: '100%',
  },
}))

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'start',
  flexDirection: 'column',
  marginBottom: '10px',
  [theme.breakpoints.up('md')]: {
    marginBottom: '0px',
  },
}))

export const StyledTextContent = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  width: '100%',
  gap: '30px',
  [theme.breakpoints.up('md')]: {
    padding: '20px',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '30%',
    gap: '0px',
  },
}))

export const StyledTitleDayOffers = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  fontWeight: '700',
  whiteSpace: 'nowrap',
  fontSize: theme.typography.fontSizeXL,
  color: theme.palette.brandAlert[50],
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSize3XL,
  },
}))

export const StyledText = clubbiStyled('div')(({ theme }) => ({
  fontWeight: '400',
  display: 'flex',
  flexWrap: 'nowrap',
  fontSize: theme.typography.fontSizeXL,
  color: theme.palette.brandGrey[100],
  lineHeight: '45px',
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSize3XL,
  },
}))

export const StyledArrowButton = clubbiStyled(ScrollButton)(({ theme, direction }) => ({
  display: 'none',
  [theme.breakpoints.between('md', 'lg')]: {
    color: theme.palette.brandWhite.main,
    background: theme.palette.brandAlert[50],
    display: 'block',
    position: 'absolute',
    right: direction === 'right' && '44px',
    left: direction === 'left' && '350px',
  },

  [theme.breakpoints.between(1200, 1700)]: {
    color: theme.palette.brandWhite.main,
    background: theme.palette.brandAlert[50],
    display: 'block',
    position: 'absolute',
    right: direction === 'right' && '80px',
    left: direction === 'left' && '445px',
  },

  [theme.breakpoints.between(1700, 2000)]: {
    color: theme.palette.brandWhite.main,
    background: theme.palette.brandAlert[50],
    display: 'block',
    position: 'absolute',
    right: direction === 'right' && '80px',
    left: direction === 'left' && '520px',
  },
}))

export const StyledCountdown = clubbiStyled('span')(({ theme }) => ({
  fontWeight: '700',
  fontSize: theme.typography.fontSizeXS,
  color: theme.palette.brandGrey[100],
  lineHeight: '12.1px',
  marginTop: '2px',
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXS,
    marginTop: '12px',
  },
}))

export const StyledButton = clubbiStyled('button')(({ theme }) => ({
  background: theme.palette.brandAlert[50],
  color: theme.palette.brandWhite.main,
  fontSize: theme.typography.fontSize3XS,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px',
  width: '110px',
  height: '30px',
  borderRadius: '4px',
  border: 'none',
  fontWeight: '700',
  gap: '4px',
  marginTop: '10px',
  marginRight: '20px',
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap',
  [theme.breakpoints.up('md')]: {
    marginTop: '12px',
    width: '192px',
    height: '44px',
    fontSize: theme.typography.fontSizeXS,
  },
}))

export const StyledButtonTitle = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '2px',
  [theme.breakpoints.up('md')]: {
    marginTop: '0px',
  },
}))

export const StyledCardContainers = clubbiStyled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  flexWrap: 'nowrap',
  gap: '8px',
  overflow: 'auto',
  paddingRight: '10px',
  [theme.breakpoints.up('md')]: {
    overflow: 'hidden',
    paddingRight: '20px',
    gap: '30px',
  },
}))
