import { CloseIcon, Drawer, clubbiStyled } from 'clubbi-ui'

export const StyledDrawerRecommended = clubbiStyled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    height: 458,
    backgroundColor: theme.palette.brandGrey[10],

    [theme.breakpoints.up('sm')]: {
      width: '905px',
      height: '689px',
      position: 'fixed',
      left: 'calc(50% - 452px)',
    },
  },
}))

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '100%',
  height: '450px',
  paddingLeft: '20px',

  [theme.breakpoints.up('sm')]: {
    height: '680px',
  },
}))

export const StayledHeader = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  paddingRight: '10px',
  width: '100%',
  paddingTop: '10px',

  color: theme.palette.brandBlack.main,

  [theme.breakpoints.up('sm')]: {
    height: '80px',
  },
}))

export const StyledCloseIcon = clubbiStyled(CloseIcon)(() => ({
  width: '40px',
}))

export const StyledTitle = clubbiStyled('span')(({ theme }) => ({
  width: '184px',
  fontSize: theme.typography.fontSizeXS,
  fontWeight: '700',

  color: theme.palette.brandGrey[100],

  [theme.breakpoints.up('sm')]: {
    width: '600px',
    fontSize: theme.typography.fontSizeL,
    alignSelf: 'end',
  },
}))

export const StyledButton = clubbiStyled('button')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXXS,
  fontWeight: '600',
  textDecoration: 'underline',
  border: 'none',

  color: theme.palette.brandAlert[50],
  backgroundColor: theme.palette.brandGrey[10],

  '&:hover': {
    textDecoration: 'underline',
    border: 'none',

    color: theme.palette.brandAlert[50],
    backgroundColor: theme.palette.brandGrey[10],
  },

  [theme.breakpoints.up('sm')]: {
    alignSelf: 'start',
  },
}))

export const StyledBackButton = clubbiStyled('button')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXXS,
  fontWeight: '600',

  color: theme.palette.brandGrey[10],
  backgroundColor: theme.palette.brandPurple[60],
  borderRadius: '4px',
  border: 'none',
  padding: '6px',

  '&:hover': {
    border: 'none',
    backgroundColor: theme.palette.brandPurple[50],
  },

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXS,
    width: '314px',
    height: '51px',
  },
}))
