import { useState, useCallback, useEffect } from 'react'
import { Alert, Container, Modal } from 'react-bootstrap'
import { WhatsappIcon } from 'react-share'
import { useAppDispatch, useAppSelector } from '../../common/hooks'
import { MerchantStatus, sessionActions } from '../../store/slices/session'

export const DeactivatedMerchantModal = (): React.ReactElement => {
  const [showModal, setShowModal] = useState(false)
  const dispatch = useAppDispatch()
  const status = useAppSelector((state) => state.session.status)

  useEffect(() => {
    if (status === MerchantStatus.DEACTIVATED) {
      setShowModal(true)
    }

    return () => {
      dispatch(sessionActions.clearIsActive())
    }
  }, [status, dispatch])

  const hideModal = useCallback(() => {
    setShowModal(false)
  }, [])

  return (
    <Modal size={'lg'} onHide={hideModal} show={showModal}>
      <Modal.Header closeButton>
        <Container>
          <Alert variant="warning">
            <Alert.Heading>Olá, cliente!</Alert.Heading>
          </Alert>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Alert variant="warning">
            <p>
              Percebemos que você possui uma divergência de cadastro conosco e isso impossibilita a
              realização de novos pedidos. Por favor, entre em contato com nosso time de atendimento
              pra que possamos resolvê-la.
            </p>
            É só{' '}
            <a
              href="https://api.whatsapp.com/send?phone=5521998868478&text=Ol%C3%A1%2C%20estou%20com%20d%C3%BAvidas%20sobre%20pend%C3%AAncias%20que%20possuo%20com%20a%20Clubbi."
              target="_blank"
              rel="noreferrer"
            >
              clicar aqui. <WhatsappIcon size={32} round={true}></WhatsappIcon>
            </a>
          </Alert>
        </Container>
      </Modal.Body>
    </Modal>
  )
}
