import React, { Dispatch, FormEvent, SetStateAction, SyntheticEvent, useState } from 'react'

import { Rating as RatingType } from '../../../store/data/types'
import { RatingStars } from '../RatingStars'
import {
  StyledButton,
  StyledButtonContainer,
  StyledCloseButton,
  StyledEvaluationLabel,
  StyledRatingPopover,
  StyledModalHeader,
  StyledComplementContainer,
  StyledContainer,
  StyledInputComment,
} from './styles/RatingPopover.style'
import { SelectEvaluationDropdown } from './SelectEvaluationDropdown'
import { putRating } from '../../../services/ratingApi'

export const RatingPopover = ({
  rating,
  openModal,
  setOpenPopover,
  currentRating,
  setCurrentRating,
  anchorEl,
  handleCloseSnackBar,
}: {
  rating: RatingType
  openModal: boolean
  setOpenPopover: Dispatch<SetStateAction<boolean>>
  currentRating?: number | null
  setCurrentRating: Dispatch<SetStateAction<number | null | undefined>>
  anchorEl: Element | null | undefined
  handleCloseSnackBar?: () => void
}) => {
  const [loading, setLoading] = useState(false)
  const [dropdownTitle, setDropdownTitle] = useState<string>('')
  const [comment, setComment] = useState('')

  const handleClose = () => {
    setDropdownTitle('')
    setComment('')
    setOpenPopover(false)
    if (handleCloseSnackBar) handleCloseSnackBar()
  }

  const handleChangeComment = (event: FormEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value
    setComment(value)
  }

  const submitRating = async () => {
    setLoading(true)

    await putRating(rating.id, {
      rating: currentRating,
      comment: comment,
      reason: dropdownTitle,
    })

    setLoading(false)
    handleClose()
    setOpenPopover(false)
    setDropdownTitle('')
    setComment('')
  }
  const handleChangeRating = (event: SyntheticEvent<Element, Event>, newValue: number | null) => {
    if (newValue) setCurrentRating(newValue)
  }

  return (
    <StyledRatingPopover
      open={openModal}
      anchorEl={anchorEl}
      anchorOrigin={
        handleCloseSnackBar
          ? {
              vertical: 'bottom',
              horizontal: 'right',
            }
          : {
              vertical: 'top',
              horizontal: 'right',
            }
      }
      transformOrigin={
        handleCloseSnackBar
          ? {
              vertical: 'top',
              horizontal: 'left',
            }
          : {
              vertical: 'top',
              horizontal: 'right',
            }
      }
    >
      <StyledContainer>
        {!handleCloseSnackBar && (
          <StyledModalHeader>
            <StyledEvaluationLabel>Como avalia esta entrega:</StyledEvaluationLabel>
            <RatingStars
              isReadOnly={false}
              disabled={false}
              handleChangeRating={handleChangeRating}
              currentRating={currentRating}
              isInCard
              onClick={null}
            />
          </StyledModalHeader>
        )}
        <StyledComplementContainer>
          <SelectEvaluationDropdown
            dropdownTitle={dropdownTitle}
            setDropdownTitle={setDropdownTitle}
            isSnackBar={handleCloseSnackBar !== undefined}
          />
          <StyledInputComment
            placeholder="Descreva o motivo da sua avaliação. "
            onInput={handleChangeComment}
          />
          <StyledButtonContainer>
            <StyledCloseButton
              size="small"
              typeButton="text"
              label="fechar"
              onClick={handleClose}
            />
            <StyledButton
              size="small"
              typeButton="filled"
              label={loading ? '...' : 'enviar'}
              onClick={submitRating}
              disabled={dropdownTitle === ''}
            />
          </StyledButtonContainer>
        </StyledComplementContainer>
      </StyledContainer>
    </StyledRatingPopover>
  )
}
