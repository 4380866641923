import { CartItem } from '../../../store/data/types'
import { useAppSelector } from '../../../common/hooks'
import { CartProductsListItem } from './CartProductsListItem'

import {
  StyledCartCardContainer,
  StyledEmptyCartMessage,
  StyledTitle,
  StyledCartProductsList,
} from './styles/CartProductsList.style'

export interface CartProductsListProps {
  items: CartItem[]
}

export const CartProductsList = () => {
  const cartItems = useAppSelector((state) =>
    Object.values(state.cart).filter((e) => e.quantity > 0)
  )
  const { isInstantPayment } = useAppSelector((state) => state.session)
  return (
    <StyledCartCardContainer removeMargin={isInstantPayment ?? false}>
      <StyledTitle>Resumo do Pedido</StyledTitle>
      <StyledCartProductsList>
        {cartItems.map((item) => (
          <CartProductsListItem item={item} key={item.product.id} />
        ))}
      </StyledCartProductsList>
      {cartItems.length == 0 && (
        <StyledEmptyCartMessage>Nenhum produto no carrinho</StyledEmptyCartMessage>
      )}
    </StyledCartCardContainer>
  )
}
