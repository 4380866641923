import { clubbiStyled as styled } from 'clubbi-ui'

export const StyledContainerTagPromotions = styled('div')<{ color: string }>(
  ({ theme, color }) => ({
    fontSize: theme.typography.fontSize4XS,
    height: '22px',
    width: '162px',
    borderRadius: '4px 4px 0 0',
    fontWeight: 700,
    top: 0,
    textAlign: 'center',
    lineHeight: '9px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    background: color,
    color: theme.palette.brandWhite.main,

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.fontSize3XS,
      height: '18px',
      width: '250px',
      flexDirection: 'row',
      gap: '5px',
    },
  })
)
