import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { FixedSizeList as List } from 'react-window'
import { themeClubbi, useMediaQuery } from 'clubbi-ui'

import { ProductCard } from '../ProductCard'
import { useAppSelector } from '../../../../common/hooks'
import { Product } from '../../../../store/data/types'
import {
  handleScrollTo,
  hasHorizontalScroll,
  showOrHideArrowsByScroll,
} from '../../../../common/ScrollUtils'

import {
  StyledSectionWrapper,
  StyledTitleWrapper,
  StyledTitle,
  StyledLink,
  StyledCorridor,
  StyledArrowButton,
} from './SectionRow.styles'

interface SectionRowProps {
  label: string
  seeMoreLink: string
  products: Product[]
  context?: string[]
  seeMoreCategories?: boolean
}

interface Data {
  products: Product[]
  label: string
}

const ProductItem = ({
  data: { products, label },
  index,
  style,
}: {
  data: Data
  index: number
  style: any
}) => {
  return (
    <>
      <div key={index} style={style}>
        {index < products.length && (
          <ProductCard product={products[index]} label={label} key={products[index].id} />
        )}
      </div>
    </>
  )
}

const TitleWrapper = ({
  seeMoreCategories,
  seeMoreLink,
  label,
}: {
  seeMoreCategories: boolean
  seeMoreLink: string
  label: string
}) => {
  const isPromotionPage = useLocation().pathname == '/promotions'

  return (
    <StyledTitleWrapper>
      <StyledTitle>{label}</StyledTitle>
      {seeMoreCategories && (
        <StyledLink to={seeMoreLink} promotionPage={isPromotionPage}>
          <span>Ver todos</span>
        </StyledLink>
      )}
    </StyledTitleWrapper>
  )
}

export const SectionRow = ({
  label,
  seeMoreLink,
  seeMoreCategories = true,
  products,
}: SectionRowProps) => {
  const { modeGenerator } = useAppSelector((state) => state.salesFlyer)
  const isDesktop = useMediaQuery(themeClubbi.breakpoints.up('md'))
  const [leftButton, setLeftButton] = useState(false)
  const [rightButton, setRightButton] = useState(true)

  const sectionWidth = isDesktop ? 266 : 170
  const sectionHeight = isDesktop ? 450 : 345

  const modeHeight = modeGenerator ? sectionHeight + 25 : sectionHeight

  let witdthCategoriesMenu = 0
  if (isDesktop) {
    witdthCategoriesMenu = 0
  }

  const { innerWidth: width } = window
  const size = 18

  const sectionCorridor = useRef(null)

  useEffect(() => {
    const element: any = sectionCorridor.current
    const list = element.children[0]

    if (!hasHorizontalScroll(list)) {
      setRightButton(false)
      setLeftButton(false)
    }
  }, [])

  const handleScrollBar = (_e: any) => {
    const element: any = sectionCorridor.current
    if (element) {
      const list = element.children[0]
      showOrHideArrowsByScroll(list, setRightButton, setLeftButton)
    }
  }

  const handleScrollToRight = () => {
    const element: any = sectionCorridor.current
    const list = element.children[0]
    handleScrollTo(list, 'right')
  }

  const handleScrollToLeft = () => {
    const element: any = sectionCorridor.current
    const list = element.children[0]
    handleScrollTo(list, 'left')
  }

  return (
    <StyledSectionWrapper id="section-wrapper">
      <TitleWrapper seeMoreCategories={seeMoreCategories} seeMoreLink={seeMoreLink} label={label} />
      {leftButton && (
        <StyledArrowButton
          direction="left"
          onClick={handleScrollToRight}
          color={themeClubbi.palette.brandWhite.main}
        />
      )}
      <StyledCorridor ref={sectionCorridor}>
        <List
          onScroll={handleScrollBar}
          className="List"
          height={modeHeight}
          itemCount={products.length}
          itemSize={sectionWidth}
          width={width - witdthCategoriesMenu - size}
          layout={'horizontal'}
          direction={'ltr'}
          itemData={{ products, label }}
        >
          {ProductItem}
        </List>
      </StyledCorridor>
      {rightButton && (
        <StyledArrowButton
          direction="right"
          onClick={handleScrollToLeft}
          color={themeClubbi.palette.brandWhite.main}
        />
      )}
    </StyledSectionWrapper>
  )
}
