import { clubbiStyled, Spinner } from 'clubbi-ui'

export const CartCardContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  backgroundColor: theme.palette.brandWhite.main,
  width: '100%',
  [theme.breakpoints.up('md')]: {
    gap: '12px',
  },
}))

export const MissionsDiscountPrice = clubbiStyled('div')(({ theme }) => ({
  color: theme.palette.brandSuccess[70],
}))

export const CartDetailSpan = clubbiStyled('span')<{ isDelivery?: boolean }>(
  ({ isDelivery, theme }) => ({
    fontSize: '12px',
    lineHeight: '109.9%',
    color: isDelivery ? theme.palette.brandGrey[60] : theme.palette.brandBlack.main,
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      color: theme.palette.brandBlack.main,
    },
  })
)

export const CartTotalSpan = clubbiStyled('span')<{ hasProfitabilities?: boolean }>(
  ({ theme, hasProfitabilities = false }) => ({
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '109.9%',
    marginBottom: hasProfitabilities ? '0px' : '16px',
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
    },
  })
)

export const CartDeliveryWarningSpan = clubbiStyled('span')(({ theme }) => ({
  fontSize: '10px',
  lineHeight: '109.9%',
  color: theme.palette.brandGrey[60],
  [theme.breakpoints.up('md')]: {
    fontSize: '12px',
  },
}))

export const CartDeliveryContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  [theme.breakpoints.up('md')]: {
    gap: '2px',
  },
}))

export const CartDetailDiv = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const StyledHR = clubbiStyled('hr')(({ theme }) => ({
  width: '100%',
  color: theme.palette.brandGrey[20],
}))

export const StyledDeliveryFree = clubbiStyled('div')(({ theme }) => ({
  color: theme.palette.brandSuccess[70],
  fontWeight: 600,
}))

export const CartProfitabilities = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginTop: '-2px',
}))

export const CartProfitabilitiesSpan = clubbiStyled('span')<{
  isNegative?: boolean
}>(({ theme, isNegative = false }) => ({
  fontSize: theme.typography.fontSize2XS,
  lineHeight: '150%',
  color: isNegative ? theme.palette.brandWarning[60] : theme.palette.brandGrey[60],
  [theme.breakpoints.up('md')]: {
    marginTop: 0,
    fontSize: '13px',
  },
}))

export const CartButtonDiv = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px 0 8px 0',
}))

export const StyledButton = clubbiStyled('button')(({ theme }) => ({
  textTransform: 'capitalize',
  fontSize: theme.typography.fontSize2XS,
  fontWeight: 600,
  height: '32px',
  padding: '4px 20px',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '4px',
  width: '100%',

  color: theme.palette.brandWhite.main,
  background: theme.palette.brandPurple[60],

  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
  },
}))

export const StyledSpinner = clubbiStyled(Spinner)(({ theme }) => ({
  width: '24px !important',
  height: '24px !important',

  color: theme.palette.brandWhite.main,
}))
