import Countdown from 'react-countdown'
import { themeClubbi, useMediaQuery } from 'clubbi-ui'

import { StyledContainerTagPromotions } from './styles/TagPromotions.style'
import { Product } from '../../../../store/data/types'
import { pad } from '../../../../common/utils'
import { useAppSelector } from '../../../../common/hooks'

interface Props {
  product: Product
}

const renderer = ({
  hours,
  minutes,
  seconds,
}: {
  hours: number
  minutes: number
  seconds: number
  completed: boolean
}): JSX.Element => {
  const timer = `termina em: ${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`
  return <span>{timer}</span>
}

export const TagPromotions = ({ product }: Props) => {
  const { websiteRegionId } = useAppSelector((state) => state.session)
  const isDesktop = useMediaQuery(themeClubbi.breakpoints.up('sm'))
  const { inOfertao, discount, supplierPrices } = product

  const showOfertao = websiteRegionId !== 77

  const getOfferType = () => {
    if (inOfertao && showOfertao) {
      return {
        msg: 'OFERTÃO',
        color: themeClubbi.palette.brandAlert.main,
      }
    }
    if (discount) {
      return {
        msg: 'OFERTA ESPECIAL',
        color: themeClubbi.palette.brandPurple.main,
      }
    }
    return {
      msg: '',
      color: themeClubbi.palette.brandWhite.main,
    }
  }

  const offerParameters = getOfferType()
  const datetimeToExpire = supplierPrices[0].datetimeToExpire

  return (
    <StyledContainerTagPromotions color={offerParameters.color}>
      <span>{offerParameters.msg}</span>
      {inOfertao && showOfertao && isDesktop && <span> • </span>}
      {inOfertao && showOfertao && <Countdown date={datetimeToExpire} renderer={renderer} />}
    </StyledContainerTagPromotions>
  )
}
