import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { getCustomProducts } from '../../../services/productApi'
import { Product } from '../../../store/data/types'
import { MediaSizes } from '../../../common/mediaBreakpoints'
import { useMediaQuery } from 'usehooks-ts'
import { ProductCard } from '../product/ProductCard'
import { clubbiStyled } from 'clubbi-ui'
import { useAppSelector } from '../../../common/hooks'
import { StyledTitle } from './styles/ProductSuggestion.style'

export const StyledDiv = clubbiStyled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '0 20px',
  flexWrap: 'wrap',
})

export const StyledCard = clubbiStyled('div')({
  padding: '20px',
})

export const ProductsSuggestions = (): React.ReactElement => {
  const [suggestions, setSuggestions] = useState<Product[]>([])
  const hasSuggestions = suggestions.length > 0

  const isDesktop = useMediaQuery(`(min-width: ${MediaSizes.ExtraLarge})`)
  const session = useAppSelector((state) => state.session)
  const { cartSessionId } = session

  const fetchSuggestions = async () => {
    const suggestedProducts = await getCustomProducts(10, cartSessionId)
    setSuggestions(suggestedProducts)
  }

  useEffect(() => {
    fetchSuggestions()
  }, [])

  return (
    <>
      {hasSuggestions && (
        <Card style={{ backgroundColor: '#ECE6FC' }} className={'mt-1 d-flex justify-center'}>
          <Card.Body style={{ padding: '20px 18px' }}>
            <div className="d-flex flex-direction-row justify-content-between align-items-center">
              <StyledTitle>Mais produtos com preços imperdíveis</StyledTitle>
            </div>
            {isDesktop ? (
              <StyledDiv>
                {suggestions.map((suggestion, index) => (
                  <StyledCard key={index}>
                    <ProductCard product={suggestion} key={index} />
                  </StyledCard>
                ))}
              </StyledDiv>
            ) : (
              <div className="d-flex flex-direction-row" style={{ overflow: 'scroll' }}>
                {suggestions.map((suggestion, index) => (
                  <ProductCard key={index} product={suggestion} data-testid="suggestion-item" />
                ))}
              </div>
            )}
          </Card.Body>
        </Card>
      )}
    </>
  )
}
